import React, { useState } from 'react';
// import './instruction.scss';
import { useTranslation } from 'react-i18next';

import "./buycoin.scss"

import vector from './Images/Vector.png';
import meta from "./Images/meta.png"
import bin from "./Images/bin.png"
import alpha from "./Images/alpha.png"
import coin from "./Images/coin.png"

import image1 from "./Images/1.png"
import image2 from "./Images/2.png"
import image3 from "./Images/3.png"

import { openLink } from '../../Utils/telegramUtils';

function BuyCoin(props) {
    const { setShow } = props;
    const { t,i18n } = useTranslation();

    const info = [
        {
            id: 1,
            image: "",
            title: t('instructions.0.title'),
            icon:"" ,
            subtext: t('instructions.0.subtext')
        },
        {
            id: 2,
            image: "",
            title: t('instructions.1.title'),
            icon: "",
            subtext: t('instructions.1.subtext')
        },
        {
            id: 3,
            image: image1,
            title: t('buycoin.0.title'),
            icon: "",
            subtext: t('instructions.2.subtext')
        },
        {
            id: 4,
            image: image2,
            title: t('buycoin.1.title'),
            icon: "",
            subtext: t('instructions.3.subtext')
        },
        {
            id: 5,
            image: image3,
            title: t('buycoin.2.title'),
            icon: "",
            subtext: "Спрашивай у нас мы подскажем🫶"
        }
    ];
    const [currentPage, setCurrentPage] = useState(0);

    const promoUrl = (i18n.language === 'ru' || i18n.language === 'uk')
    ? "https://www.youtube.com/watch?v=AkCyHOUfjjY&t=1s"
    : "https://www.youtube.com/watch?v=sQH0y823W6U&t=1s";

    const handleNext = () => {
        if (currentPage === info.length - 1) {
            openLink(promoUrl)
            setShow(false)
        } else {
            setCurrentPage((currentPage + 1) % info.length);
        }
    };

    return (
        <div className='outer_container_instruction'>
            <div className='container_glow_instruction'>
                <img src={coin} className='buycoin'/>

                {currentPage == 0 ?
                    <>
                        <div className='shadow pink'></div>
                        <div className='shadow blue'></div>
                        <div className='shadow black'></div>
                        <h1 style={{ textAlign: "center", color: "white" }}>{t('buycoin.5.title')}</h1>
                        <div className='icon_finger'>☝️ </div>
                        <div className='make_profit'>
                           
                            {t('buycoin.3.title')}
                           
                        </div>
                        <div className='next_container'>
                            <button className='button_next' onClick={handleNext}>
                                <img src={vector} alt='Next' />
                            </button>
                            {currentPage + 1}/5
                        </div>
                    </>
                    :currentPage == 1 ? <>
                        <div className='shadow pink'></div>
                        <div className='shadow blue'></div>
                        <div className='shadow black'></div>
                        <h1 style={{ textAlign: "center", color: "white" }}>      
                            {t('buycoin.4.title')}
                        </h1>
                        <div className='flex_buy'>
                            <img src={alpha}/>
                            <div>AlphaDex</div>
                        </div>
                        <div className='flex_buy'>
                            <img src={meta}/>
                            <div>Metamask</div>
                        </div>
                        <div className='flex_buy'>
                            <img src={bin}/>
                            <div>Binance 
                            WEB 3 wallet</div>
                        </div>
                        <div className='next_container'>
                            <button className='button_next' onClick={handleNext}>
                                <img src={vector} alt='Next' />
                            </button>
                            {currentPage + 1}/5
                        </div>
                    
                    </>:
                    <>

                        <h1 style={{ color: "white" }} className='mt-10'>{info[currentPage].title}</h1>

                        <div className='next_container'>
                            <button className='button_next' onClick={handleNext}>
                                <img src={vector} alt='Next' />
                            </button>
                            {currentPage + 1}/5
                        </div>
                        <img
                            className='image_instruction'
                            src={info[currentPage].image}
                        />
                        <div className='shadow pink'></div>
                        <div className='shadow blue'></div>
                        <div className='shadow black'></div>
                    </>
                }


            </div>
        </div>
    );
}

export default BuyCoin;
